const LOCALE_ENTRY = 0

export default [
  { text: LOCALE_ENTRY, value: 'alarm' },
  { text: LOCALE_ENTRY, value: 'star' },
  { text: LOCALE_ENTRY, value: 'favorite' },
  { text: LOCALE_ENTRY, value: 'flash_on' },
  { text: LOCALE_ENTRY, value: 'assignment' },
  { text: LOCALE_ENTRY, value: 'warning' },
  { text: LOCALE_ENTRY, value: 'attach_money' },
  { text: LOCALE_ENTRY, value: 'payment' },
  { text: LOCALE_ENTRY, value: 'home' },
  { text: LOCALE_ENTRY, value: 'play_circle_outline' },
  { text: LOCALE_ENTRY, value: 'email' },
  { text: LOCALE_ENTRY, value: 'phone' },
  { text: LOCALE_ENTRY, value: 'insert_chart' },
  { text: LOCALE_ENTRY, value: 'directions_bike' },
  { text: LOCALE_ENTRY, value: 'card_travel' }
]
