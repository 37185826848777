<template>
  <div class="card event-item eventpopover">
    <div class="grid-x">
      <div class="cell large-6">
        <div class="card__section">
          <div class="event-item__header">
            <h2>{{ $t("calendar.create_item") }}</h2>
          </div>
        </div>
      </div>
      <div class="cell large-6">
        <div class="card__section">
          <div class="event-item__header text-right">
            <span
              class="ab-button--white ab-button--cancel"
              @click="closeCheck(close)"
              >{{ $t("app.cancel") }} <i class="icon-close"></i
            ></span>
            <span
              class="ab-button--white ab-button--save"
              :disabled="!isValid"
              @click="add"
              >{{ $t("app.save") }} <i class="icon-save"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="cell large-12"><hr /></div>

      <div class="cell large-12">
        <div class="card__section eventpopover-card-section">
          <ab-input-field
            :label="$t('app.title')"
            v-model="details.title"
            type="text"
          ></ab-input-field>

          <ab-textarea
            :label="$t('app.description')"
            v-model="details.description"
            icon="icon-description"
          ></ab-textarea>

          <div
            style="display: none"
            class="event-item__field event-item__field--icon"
          >
            <i class="icon-brand-goals"></i>
            <v-select
              single-line
              hide-details
              solo
              flat
              full-width
              :items="calendarItems"
              :first="details.color"
              :value="details.calendar"
              :text="details.calendar"
              placeholder="Kies een branding doel"
              v-model="details.calendar"
              return-object
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-tile-content>
                  <div class="ds-color-option">
                    <span v-text="item.text"></span>
                  </div>
                </v-list-tile-content>
              </template>
            </v-select>
            <label>{{ $t("calendar.branding_goal") }}</label>
          </div>

          <div class="spacer--30"></div>

          <ab-select
            :label="$t('calendar.assignee')"
            :options="usersArray"
            v-model="details.user_id"
            :hasUsers="true"
          ></ab-select>

          <div class="spacer--30"></div>

          <div class="range-picker">
            <ab-date
              v-model="details.date"
              mode="single"
              :label="$t('calendar.deadline')"
              placement="bottom-start"
            />
          </div>

          <div class="spacer--30"></div>

          <ab-select
            :label="$t('calendar.reminders')"
            :options="reminders"
            v-model="selectedReminder"
            placeholder="Voeg een reminder toe"
            @input="addToReminders"
            placement="bottom-start"
          ></ab-select>
          <!-- start reminders -->
          <div
            v-for="(selectedReminder, index) in this.selectedReminders"
            :Key="index"
            class="ds-reminders"
          >
            <div class="ds-reminders__item">
              <span class="ds-reminders__title">{{
                $t(`reminders.${selectedReminder.value}`)
              }}</span>

                  <span
                @click="deleteReminder(selectedReminder.value)"
                class="ds-reminders__remove"
              ><i class="icon-icon-trash"></i
              ></span>

            </div>
          </div>
          <!-- end reminders -->
        </div>
      </div>
    </div>
    <ab-loader v-if="showLoader"></ab-loader>
  </div>
</template>

<script>
import { Calendar, CalendarEvent, Day, Functions as fn } from 'dayspan'
import moment from 'moment'
import backend from '@/backend'

export default {
  name: 'dsCalendarEventCreatePopover',
  props: {
    calendarEvent: {
      required: true,
      type: CalendarEvent
    },

    calendar: {
      required: true,
      type: Calendar
    },

    close: {
      type: Function
    },

    formats: {
      validate (x) {
        return this.$dsValidate(x, 'formats')
      },
      default () {
        return this.$dsDefaults().formats
      }
    },

    icons: {
      validate (x) {
        return this.$dsValidate(x, 'icons')
      },
      default () {
        return this.$dsDefaults().icons
      }
    },

    labels: {
      validate (x) {
        return this.$dsValidate(x, 'labels')
      },
      default () {
        return this.$dsDefaults().labels
      }
    },

    prompts: {
      validate (x) {
        return this.$dsValidate(x, 'prompts')
      },
      default () {
        return this.$dsDefaults().prompts
      }
    },

    busyOptions: {
      type: Array,
      default () {
        return this.$dsDefaults().busyOptions
      }
    }
  },

  computed: {
    usersArray () {
      const userArray = []
      for (const key in this.users) {
        const user = this.users[key]
        userArray.push({
          value: user.id,
          title: user.text,
          avatar: user.avatar
        })
      }
      return userArray
    },

    slotData () {
      return {
        calendarEvent: this.calendarEvent,
        calendar: this.calendar || '#cc4b37',
        close: this.close,
        details: this.details
      }
    },

    classes () {
      return {
        'ds-event-cancelled': this.calendarEvent.cancelled
      }
    },

    styleHeader () {
      return {
        backgroundColor: this.details.calendar.color || '#cc4b37',
        color: this.details.forecolor
      }
    },

    styleText () {
      return {
        color: this.details.forecolor
      }
    },

    startDate () {
      return this.calendarEvent.start.format(this.formats.start)
    },

    busyness () {
      return this.details.busy ? this.labels.busy : this.labels.free
    },

    isValid () {
      return this.$dayspan.isValidEvent(
        this.details,
        this.calendarEvent.schedule,
        this.calendarEvent
      )
    },
    occurs () {
      return this.$dayspan.getEventOccurrence(
        this.calendarEvent.schedule,
        this.calendarEvent.start,
        this.labels,
        this.formats
      )
    }
  },
  data: vm => ({
    calendarItems: [],
    users: [],
    details: vm.buildDetails(),
    showLoader: false,
    dirty: false,
    date: moment(String(vm.calendarEvent.start)).toDate(),
    reminders: [],
    selectedReminder: '',
    selectedReminders: []
  }),
  mounted () {
    this.details.date = this.date

    backend.get(`${process.env.VUE_APP_URL}/calendars`).then(response => {
      for (const key in response.data) {
        const calendar = response.data[key]
        this.calendarItems.push({
          text: calendar.name,
          value: calendar.name,
          color: calendar.color
        })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/users`).then(response => {
      for (const key in response.data) {
        const user = response.data[key]
        this.users.push({
          id: user.id,
          text: user.name + ' ' + user.surname,
          value: user.name,
          surname: user.surname,
          avatar: user.avatar
        })
      }
    })

    // update reminders data prop
    this.reminders = [
      { title: this.$t('reminders.5 minutes'), value: '5 minutes' },
      { title: this.$t('reminders.30 minutes'), value: '30 minutes' },
      { title: this.$t('reminders.1 hour'), value: '1 hour' },
      { title: this.$t('reminders.1 day'), value: '1 day' },
      { title: this.$t('reminders.2 days'), value: '2 days' },
      { title: this.$t('reminders.1 week'), value: '1 week' },
      { title: this.$t('reminders.2 weeks'), value: '2 weeks' }
    ]
  },
  methods: {
    closeCheck (close) {
      if (!this.dirty) {
        close()
        return
      }
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.cancel_create'),
        width: '100%',
        buttons: [
          {
            title: this.$t('app.no'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.yes'),
            default: true,
            handler: () => {
              close()
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    dateFormat (value) {
      return moment(String(value), 'DD-MM-YYYY').format('DD/MM/YYYY')
    },
    dateNow () {
      return moment().format('DD/MM/YYYY')
    },
    edit () {
      const ev = this.getEvent('create-edit')

      this.$emit('create-edit', ev)

      this.finishEvent(ev)
    },
    add () {
      this.showLoader = true
      setTimeout(() => {
        this.save()
      }, 50)
    },
    save () {
      const ev = this.getEvent('creating')
      this.$emit('creating', ev)

      if (!ev.handled && ev.details && ev.calendarEvent) {
        const targetDate = moment(this.date, 'YYYY-MM-DD')
        if (
          ev.calendarEvent.start.format('YYYYMMDD') !==
          targetDate.format('YYYYMMDD')
        ) {
          var target = Day.parse(targetDate.format('YYYY-MM-DD'))
          var evMove = this.getEvent('move', { target })
          ev.calendarEvent.move(evMove.target)
          ev.calendarEvent.day = new Day(targetDate)
        }

        ev.created = ev.calendarEvent.event

        this.$dayspan.setEventDetails(
          ev.details,
          ev.created.data,
          ev.created,
          ev.calendarEvent
        )

        if (ev.calendar) {
          ev.calendar.addEvent(ev.created)
          ev.added = true
        }

        this.$emit('created', ev)

        ev.calendar && ev.refresh && ev.calendar.refreshEvents()

        ev.handled = true

        this.$emit('event-create', ev.created)
      }
      this.finishEvent(ev)
    },

    finishEvent (ev) {
      const close = ev.close
      let date = ''
      if (this.details.date) {
        date = moment(this.details.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      } else {
        date = moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD')
      }

      backend
        .post(`${process.env.VUE_APP_URL}/events`, {
          title: ev.details.title,
          calendar: ev.details.calendar.text,
          content: ev.details.description,
          date: date,
          user_id: ev.details.user_id,
          reminders: this.selectedReminders
        })
        .then(response => {
          this.$notify({
            group: 'custom-template',
            title: false,
            position: 'bottom left',
            text: this.$t('calendar.item_added')
          })
          this.$emit('create-popover-closed', ev)
          this.close()
        })
    },

    buildDetails () {
      const details = this.$dayspan.copyEventDetails(
        this.calendarEvent.event.data
      )
      details.title = ''
      return details
    },
    getEvent (type, extra = {}) {
      return fn.extend(
        {
          type: type,
          calendarEvent: this.calendarEvent,
          calendar: this.calendar,
          // close: this.close,
          details: this.details,
          handled: false,
          added: false,
          refresh: true,
          close: true,
          $vm: this,
          $element: this.$el
        },
        extra
      )
    },
    // start reminders
    deleteReminder (value) {
      this.selectedReminders = this.selectedReminders.filter(reminder => {
        return reminder.value !== value
      })
    },
    getReminder (value) {
      const result = this.reminders.filter(reminder => {
        return value === reminder.value
      })

      return result[0]
    },
    reminderAlreadyAdded (value) {
      const result = this.selectedReminders.filter(reminder => {
        return value === reminder.value
      })

      return result.length > 0
    },
    addToReminders (value) {
      if (!this.reminderAlreadyAdded(value)) {
        const reminder = this.getReminder(value)

        this.selectedReminders.push({
          title: this.$t(reminder.value),
          value: reminder.value
        })

        this.selectedReminder = ''
      }
    } // end of reminders
  },
  watch: {
    details: {
      handler (newVal, oldVal) {
        this.dirty = true
      },
      deep: true
    }
  }
}
</script>
