  <template>
  <default-layout>
    <portal to="pageHeaderRight">
      <contact-dropdown />
    </portal>

    <v-app id="dayspan" v-cloak>
      <ds-calendar-app
        ref="app"
        :calendar="calendar"
        :available-calendars="calendars"
        :read-only="readOnly"
        @change="triggerCalendarChange"
      >
        <template slot="eventPopover" slot-scope="slotData">
          <ds-calendar-event-popover
            v-bind="slotData"
            :read-only="readOnly"
            @finish="triggerCalendarChange"
          ></ds-calendar-event-popover>
        </template>

        <template
          slot="eventCreatePopover"
          slot-scope="{ placeholder, calendar }"
        >
          <ds-calendar-event-create-popover
            :calendar-event="placeholder"
            :calendar="calendar"
            :close="$refs.app.$refs.calendar.clearPlaceholder"
            @create-edit="$refs.app.editPlaceholder"
            @create-popover-closed="triggerCalendarChange"
          ></ds-calendar-event-create-popover>
        </template>

        <template slot="eventTimeTitle" slot-scope="{ calendarEvent, details }">
          <div>
            <v-icon
              class="ds-ev-icon"
              v-if="details.icon"
              size="14"
              :style="{ color: details.forecolor }"
            >
              {{ details.icon }}
            </v-icon>
            <strong class="ds-ev-title">{{ details.title }}</strong>
          </div>
          <div class="ds-ev-description">
            {{ getCalendarTime(calendarEvent) }}
          </div>
        </template>
      </ds-calendar-app>
    </v-app>
  </default-layout>
</template>

<script>
import * as moment from "moment";
import backend from "@/backend";
import Vue from "vue";
import Vuetify from "vuetify";
import DaySpanVuetify from "./components/plugin";
import dutchLang from "./components/locales/nl";
import englishLang from "./components/locales/en";

Vue.use(Vuetify);
Vue.use(DaySpanVuetify, {
  methods: {
    getDefaultEventColor() {
      return "#000000";
    }
  }
});
Vue.$dayspan.addLocale("nl", dutchLang);
Vue.$dayspan.addLocale("en", englishLang);

import { dsMerge } from "./components/functions";
import { Calendar, Weekday, Month, Sorts } from "dayspan";

export default {

  name: "dayspan",

  data: vm => ({
    calendar: Calendar.months(),
    readOnly: false,
    defaultEvents: [],
    calendars: [],
  }),

  mounted() {
    // Set calendar locale based on user locale.
    this.setLocale(this.$store.state.user.locale)

    window.app = this.$refs.app

    let startDate = this.calendar.start.monthIdentifier + '01'
    let endDate =
      this.calendar.start.monthIdentifier +
      '' +
      this.calendar.start.lastDayOfMonth

    this.loadCalendarData(startDate, endDate, true)
    this.getAvailableCalendars()
  },
  methods: {
    getCalendarTime(calendarEvent) {
      let sa = calendarEvent.start.format("a");
      let ea = calendarEvent.end.format("a");
      let sh = calendarEvent.start.format("h");
      let eh = calendarEvent.end.format("h");

      if (calendarEvent.start.minute !== 0) {
        sh += calendarEvent.start.format(':mm')
      }

      if (calendarEvent.end.minute !== 0) {
        eh += calendarEvent.end.format(':mm')
      }

      return sa === ea ? sh + ' - ' + eh + ea : sh + sa + ' - ' + eh + ea
    },

    getAvailableCalendars () {
      backend
        .get(`${process.env.VUE_APP_URL}/calendars`)
        .then(response => {
          this.calendars = response.data
        })
    },

    loadCalendarData (start, end, trigger) {
      let requestURL = `${process.env.VUE_APP_URL}/events?start=${start}&end=${end}`
      let selectedCalendars = localStorage.getItem('selectedCalendars')
      if (selectedCalendars !== null && selectedCalendars.length !== 0) {
        selectedCalendars = selectedCalendars.split(',')
        for (let key in selectedCalendars) {
          if (selectedCalendars[key] !== '') {
            requestURL = requestURL + '&calendars[]=' + selectedCalendars[key]
          }
        }
      }

      backend
        .get(requestURL)
        .then(response => {
          let events = []

          for (let key in response.data) {
            let event = response.data[key]
            let date = new Date(event.date)
            let eventData = {
              data: {
                id: event.id,
                title: event.title,
                description: event.content,
                editable: event.editable,
                user_id: event.user_id,
                reminders: event.reminders
              },
              schedule: {
                dayOfMonth: [date.getDate()],
                month: [date.getMonth()],
                year: [date.getFullYear()],
                duration: event.duration,
                durationUnit: event.duration_unit
              }
            };
            if (event.calendar != null) {
              eventData.data.calendar = {
                text: event.calendar.name,
                color: event.calendar.color,
                value: event.calendar.name
              };
            } else {
              eventData.data.calendar = {
                text: this.$t("calendar.no_target_selected"),
                color: "#cc4b37",
                value: this.$t("calendar.no_target_selected")
              };
            }
            if (event.user != null) {
              eventData.data.user = {
                text: event.user.name + " " + event.user.surname,
                value: event.user.name + " " + event.user.surname,
                avatar: event.user.avatar
              };
            } else {
              eventData.data.user = {
                text: this.$t("calendar.abrnd_user"),
                value: this.$t("calendar.abrnd_user"),
                avatar:
                  "https://img.pngio.com/user-computer-icons-download-person-icon-png-download-900980-person-icon-png-900_980.jpg"
              };
            }
            events.push(eventData);
          }
          this.defaultEvents = events;

          let defaults = this.$dayspan.getDefaultEventDetails();

          this.defaultEvents.forEach(ev => {
            ev.data = dsMerge(ev.data, defaults);
          });
          this.calendar.setEvents(this.defaultEvents);
        });
    },

    setLocale(code) {
      moment.locale(code);

      this.$dayspan.setLocale(code);
      this.$dayspan.refreshTimes();

      this.$refs.app.$forceUpdate();
    },

    triggerCalendarChange() {
      let startDate = this.calendar.start.monthIdentifier + "01";
      let endDate =
        this.calendar.start.monthIdentifier +
        "" +
        this.calendar.start.lastDayOfMonth;

      this.loadCalendarData(startDate, endDate);
    }
  }
};
</script>

<style>
body,
html,
#app {
  width: 100%;
  height: 100%;
}
</style>
