<template>
  <div>
    <div
      v-for="(reminder, index) in reminders"
      :key="index"
      class="ds-reminders"
    >
      <div class="ds-reminders__item">
        <i class="icon-stopwatch ds_reminders__bell"></i>
        <span class="ds-reminders__title">{{
          $t(`reminders.${reminder.value}`)
        }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CalendarEventPopoverReminders",
  props: {
    reminders: {
      type: Array
    }
  }
};
</script>
