<template>
  <div class="card event-item eventpopover">
    <div class="grid-x">
      <div
        class="
          cell
          large-6
          event-item__header__edit event-item__header__radius-left-top
        "
      >
        <div class="card__section">
          <div class="event-item__header">
            <h2>{{ $t("calendar.edit_item") }}</h2>
          </div>
        </div>
      </div>
      <div
        class="
          cell
          large-6
          event-item__header__edit event-item__header__radius-left-top
        "
      >
        <div class="card__section eventpopover-card-section">
          <div class="event-item__header text-right">
            <span class="ab-button--white ab-button--cancel" @click="cancel"
              >{{ $t("app.cancel") }} <i class="icon-close"></i
            ></span>
            <span
              class="ab-button--white ab-button--save"
              v-if="!isReadOnly"
              :disabled="!canSave"
              @click.stop="save"
              >{{ $t("app.save") }} <i class="icon-save"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="cell large-12">
        <hr />
      </div>

      <div class="cell medium-12">
        <div class="card__section">
          <ab-input-field
            :label="$t('app.title')"
            v-model="details.title"
            type="text"
          ></ab-input-field>
          <ab-textarea
            :label="$t('app.description')"
            v-model="details.description"
            icon="icon-description"
          ></ab-textarea>
          <div
            style="display: none"
            class="event-item__field event-item__field--icon"
            v-if="details.calendar"
          >
            <i class="icon-brand-goals"></i>
            <v-select
              single-line
              hide-details
              solo
              flat
              full-width
              :items="calendarItems"
              :color="details.color"
              :placeholder="$t('calendar.select_branding_goal')"
              v-model="details.calendar"
              return-object
            >
              <template slot="item" slot-scope="{ item }">
                <v-list-tile-content>
                  <div class="ds-color-option">
                    <span v-text="item.text"></span>
                  </div>
                </v-list-tile-content>
              </template>
            </v-select>
            <label>{{ $t("calendar.branding_goal") }}</label>
          </div>
          <div class="spacer--30"></div>
          <ab-select
            :label="$t('calendar.assignee')"
            :options="usersArray"
            v-model="details.user_id"
            :hasUsers="true"
          ></ab-select>
          <div class="spacer--30"></div>
          <div class="range-picker">
            <ab-date
              v-model="date"
              mode="single"
              :label="$t('calendar.deadline')"
              placement="top-start"
            />
          </div>
          <div class="spacer--30"></div>

          <ab-select
            :label="$t('calendar.reminders')"
            :options="reminders"
            v-model="selectedReminder"
            placeholder="Voeg een reminder toe"
            @input="addToReminders"
          ></ab-select>

          <div
            v-for="(selectedReminder, index) in this.selectedReminders"
            :Key="index"
            class="ds-reminders"
          >
            <div class="ds-reminders__item">
              <span class="ds-reminders__title">{{
                $t(`reminders.${selectedReminder.value}`)
              }}</span>

              <span
                @click="deleteReminder(selectedReminder.value)"
                class="ds-reminders__remove"
                ><i class="icon-icon-trash"></i
              ></span>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  Day,
  Calendar,
  CalendarEvent,
  Schedule,
  Functions as fn
} from 'dayspan'
import backend from '@/backend'
import Datetime from 'vue-datetime'
import moment from 'moment'
import 'vue-datetime/dist/vue-datetime.css'

Vue.use(Datetime)

export default {
  name: 'dsEvent',

  props: {
    close: {
      type: Function
    },
    targetSchedule: {
      required: true,
      type: Schedule
    },

    targetDetails: {
      type: Object,
      required: true
    },

    calendarEvent: {
      type: CalendarEvent
    },

    calendar: {
      type: Calendar
    },

    day: {
      type: Day
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    labels: {
      validate (x) {
        return this.$dsValidate(x, 'labels')
      },
      default () {
        return this.$dsDefaults().labels
      }
    },

    hasTitle: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasTitle
      }
    },

    hasCancel: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasCancel
      }
    },

    hasSave: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasSave
      }
    },

    hasTabs: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasTabs
      }
    },

    hasDetails: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasDetails
      }
    },

    hasForecast: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasForecast
      }
    },

    hasExclusions: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasExclusions
      }
    },

    hasInclusions: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasInclusions
      }
    },

    hasCancelled: {
      type: Boolean,
      default () {
        return this.$dsDefaults().hasCancelled
      }
    },

    busyOptions: {
      type: Array,
      default () {
        return this.$dsDefaults().busyOptions
      }
    },
    formats: {
      validate (x) {
        return this.$dsValidate(x, 'formats')
      },
      default () {
        return this.$dsDefaults().formats
      }
    }
  },

  data: vm => ({
    calendarItems: [],
    users: [],
    tab: 'details',
    schedule: new Schedule(),
    details: vm.$dayspan.getDefaultEventDetails(),
    isChanged: false,
    date: '',
    reminders: [],
    selectedReminder: '',
    selectedReminders: []
  }),
  mounted () {
    this.date = moment(String(this.calendarEvent.start.date)).toDate()
    backend.get(`${process.env.VUE_APP_URL}/calendars`).then(response => {
      for (const key in response.data) {
        const calendar = response.data[key]
        this.calendarItems.push({
          text: calendar.name,
          value: calendar.name,
          color: calendar.color
        })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/users`).then(response => {
      for (const key in response.data) {
        const user = response.data[key]
        this.users.push({
          id: user.id,
          text: user.name + ' ' + user.surname,
          value: user.name,
          surname: user.surname,
          avatar: user.avatar
        })
      }
    })

    // update reminders data prop
    this.reminders = [
      { title: this.$t('reminders.5 minutes'), value: '5 minutes' },
      { title: this.$t('reminders.30 minutes'), value: '30 minutes' },
      { title: this.$t('reminders.1 hour'), value: '1 hour' },
      { title: this.$t('reminders.1 day'), value: '1 day' },
      { title: this.$t('reminders.2 days'), value: '2 days' },
      { title: this.$t('reminders.1 week'), value: '1 week' },
      { title: this.$t('reminders.2 weeks'), value: '2 weeks' }
    ]

    for (const key in this.details.reminders) {
      const reminder = this.details.reminders[key]

      this.selectedReminders.push({
        title: reminder.title,
        value: reminder.value
      })
    }
  },
  watch: {
    targetSchedule: {
      handler: 'updateSchedule',
      immediate: true
    },

    targetDetails: {
      handler: 'updateDetails',
      immediate: true
    },
    details: {
      deep: true,
      handler () {
        this.isChanged = true
      }
    }
  },

  computed: {
    usersArray () {
      const userArray = []
      for (const key in this.users) {
        const user = this.users[key]
        userArray.push({
          value: user.id,
          title: user.text,
          avatar: user.avatar
        })
      }
      return userArray
    },
    slotData () {
      return {
        targetSchedule: this.targetSchedule,
        targetDetails: this.targetDetails,
        schedule: this.schedule,
        details: this.details,
        busyOptions: this.busyOptions,
        day: this.day,
        calendar: this.calendar,
        calendarEvent: this.calendarEvent,
        user: this.user,
        userEvent: this.userEvent,
        labels: this.labels,
        readOnly: this.readOnly
      }
    },

    styleButton () {
      return {
        color: this.details.forecolor
      }
    },

    classes () {
      return {
        'ds-has-cancel': this.hasCancel,
        'ds-event-small': this.$vuetify.breakpoint.smAndDown
      }
    },

    canSave () {
      return this.$dayspan.isValidEvent(
        this.details,
        this.schedule,
        this.calenderEvent
      )
    },

    repeats () {
      return !this.schedule.isSingleEvent()
    },

    showTitle () {
      return this.$dayspan.supports.title && this.hasTitle
    },

    showCancels () {
      return (
        this.$dayspan.features.cancel &&
        this.repeats &&
        this.hasCancelled &&
        !this.schedule.cancel.isEmpty()
      )
    },

    showForecast () {
      return this.$dayspan.features.forecast && this.repeats && this.hasForecast
    },

    showExclusions () {
      return (
        this.$dayspan.features.exclude &&
        this.repeats &&
        this.hasExclusions &&
        !this.schedule.exclude.isEmpty()
      )
    },

    showInclusions () {
      return (
        this.$dayspan.features.include &&
        this.repeats &&
        this.hasInclusions &&
        !this.schedule.include.isEmpty()
      )
    },

    isReadOnly () {
      return this.readOnly || this.$dayspan.readOnly || !this.details.editable
    }
  },
  methods: {
    dateFormat (value, format) {
      if (value == null) {
        return moment().format(format)
      }
      return moment(String(value)).format(format)
    },
    save () {
      const ev = this.getEvent('save')

      this.$emit('save', ev)

      if (!ev.handled) {
        if (ev.target && ev.schedule) {
          ev.target.set(ev.schedule)
        }

        if (ev.calendarEvent) {
          this.$dayspan.setEventDetails(
            ev.details,
            ev.targetDetails,
            ev.calendarEvent.event,
            ev.calendarEvent
          )

          this.$emit('update', ev)

          this.$emit('event-update', ev.calendarEvent.event)
          const date = moment(this.date, 'DD-MM-YYYY').format('YYYY-MM-DD')

          backend
            .put(`${process.env.VUE_APP_URL}/events/` + ev.details.id, {
              title: ev.details.title,
              calendar: ev.details.calendar.text,
              content: ev.details.description,
              date: date,
              user_id: ev.details.user_id,
              reminders: this.selectedReminders
            })
            .then(response => {
              this.$emit('event-update-closed', ev)
              this.$emit('saved', ev)

              this.$notify({
                group: 'custom-template',
                title: false,
                position: 'bottom left',
                text: this.$t('calendar.item_updated')
              })
              this.close()
            })
        } else if (ev.create) {
          ev.created = this.$dayspan.createEvent(ev.details, ev.schedule)

          if (ev.calendar) {
            ev.calendar.addEvent(ev.created)
            ev.added = true
          }
          this.$emit('create', ev)
        }

        if (ev.calendar && ev.refresh) {
          ev.calendar.refreshEvents()
        }

        ev.handled = true

        if (ev.created) {
          this.$emit('event-create', ev.created)
        }
      }
    },

    actioned (ev) {
      this.$emit('actioned', ev)
    },

    cancel () {
      if (!this.isChanged) {
        this.$emit('cancel', this.getEvent('cancel'))
        return
      }
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('calendar.close_without_save'),
        buttons: [
          {
            title: this.$t('app.no'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.yes'),
            default: true,
            handler: () => {
              this.$emit('cancel', this.getEvent('cancel'))
              this.$modal.hide('dialog')
            }
          }
        ]
      })
    },
    hideConfirmModel (id) {
      this.$modal.hide(id)
    },
    cancelSaveConfirmModel () {
      this.$emit('cancel', this.getEvent('cancel'))
    },
    updateSchedule (schedule) {
      this.schedule = schedule.clone()
      this.tab = 'details'
    },

    updateDetails (details) {
      this.details = this.$dayspan.copyEventDetails(details)
      this.tab = 'details'
    },

    getEvent (type, extra = {}) {
      return fn.extend(
        {
          type: type,
          day: this.day,
          schedule: this.schedule,
          target: this.targetSchedule,
          details: this.details,
          targetDetails: this.targetDetails,
          calendar: this.calendar,
          calendarEvent: this.calendarEvent,
          user: this.user,
          handled: false,
          refresh: true,
          create: true,
          added: false,
          $vm: this,
          $element: this.$el
        },
        extra
      )
    },
    // start reminders
    deleteReminder (value) {
      this.selectedReminders = this.selectedReminders.filter(reminder => {
        return reminder.value !== value
      })
    },
    getReminder (value) {
      const result = this.reminders.filter(reminder => {
        return value === reminder.value
      })

      return result[0]
    },
    reminderAlreadyAdded (value) {
      const result = this.selectedReminders.filter(reminder => {
        return value === reminder.value
      })

      return result.length > 0
    },
    addToReminders (value) {
      if (!this.reminderAlreadyAdded(value)) {
        const reminder = this.getReminder(value)

        this.selectedReminders.push({
          title: this.$t(reminder.value),
          value: reminder.value
        })

        this.selectedReminder = ''
      }
    } // end of reminders
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/init/variables/variables";

.remove-button {
  font-family: $body-font-family;
  font-weight: 500;
  margin-left: 15px;
  cursor: pointer;
}
</style>
