<template>
  <div
    class="ds-day"
    :class="classesDay"
    @mouseenter="mouseEnterDay"
    @mouseleave="mouseLeaveDay"
    @mousemove.stop="mouseMoveDay"
    @mousedown="mouseDownDay"
    @mouseup="mouseUpDay"
    @click.stop="add"
    @dragstart.prevent
  >
    <div class="ds-day--inner">
      <div :class="classesHeader">
        <a
          class="ds-dom"
          href
          :class="classesDayOfMonth"
          @click.stop.prevent="viewDay"
          @mousedown.stop
        >
          {{ dayOfMonth }}
        </a>
      </div>

      <div
        class="ds-day--inner-scroll"
        :class="[$store.state.is_brand_manager ? '' : 'hide-add']"
      >
        <template v-for="(event, i) in visibleEvents">
          <ds-calendar-event
            v-bind="{ $scopedSlots }"
            v-on="$listeners"
            :key="event.id"
            :calendar-event="event"
            :calendar="calendar"
            :index="i"
          ></ds-calendar-event>
        </template>
        <div v-if="hasPlaceholder && $store.state.is_brand_manager">
          <ds-calendar-event-placeholder
            v-bind="{ $scopedSlots }"
            v-on="$listeners"
            :day="day"
            :placeholder="placeholder"
            :placeholder-for-create="placeholderForCreate"
            :calendar="calendar"
            :index="visibleEvents.length"
          ></ds-calendar-event-placeholder>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Day, Calendar, CalendarEvent, Functions as fn } from "dayspan";

export default {
  name: "dsDay",

  props: {
    day: {
      required: true,
      type: Day
    },

    calendar: {
      required: true,
      type: Calendar
    },

    placeholder: {
      type: CalendarEvent
    },

    placeholderForCreate: {
      type: Boolean,
      default: false
    },

    formats: {
      validate(x) {
        return this.$dsValidate(x, "formats");
      },
      default() {
        return this.$dsDefaults().formats;
      }
    }
  },

  computed: {
    classesDay() {
      return {
        "ds-today": this.day.currentDay,
        "ds-first-day-day": this.day.dayOfMonth === 1,
        "ds-out-calendar": !this.day.inCalendar
      };
    },

    classesHeader() {
      return {
        "ds-out-calendar": !this.day.inCalendar
      };
    },

    classesDayOfMonth() {
      return {
        "ds-today-dom": this.day.currentDay
      };
    },

    showMonth() {
      return this.day.dayOfMonth === 1;
    },

    dayOfMonth() {
      return this.day.dayOfMonth;
    },

    month() {
      return this.day.format(this.formats.month);
    },

    hasPlaceholder() {
      return this.placeholder && this.placeholder.time.matchesDay(this.day);
    },

    visibleEvents() {
      return this.day.events.filter(this.isVisible);
    }
  },

  methods: {
    isVisible(calendarEvent) {
      if (
        this.$dayspan.features.hideOnMove &&
        this.placeholder &&
        this.placeholder.event === calendarEvent.event
      ) {
        return false;
      }

      return true;
    },

    add() {
      this.$emit("add", this.day);
    },

    viewDay(event) {
      this.$emit("view-day", this.day);
    },

    mouseEnterDay($event) {
      this.$emit("mouse-enter-day", this.day);
    },

    mouseLeaveDay($event) {
      this.$emit("mouse-leave-day", this.day);
    },

    mouseMoveDay($event) {
      this.$emit("mouse-move-day", this.getEvent("mouse-move-day", $event));
    },

    mouseDownDay($event) {
      this.$emit("mouse-down-day", this.getEvent("mouse-down-day", $event));
    },

    mouseUpDay($event) {
      this.$emit("mouse-up-day", this.getEvent("mouse-up-day", $event));
    },

    getEvent(type, $event, extra = {}) {
      return fn.extend(
        {
          type: type,
          day: this.day,
          calendar: this.calendar,
          left: $event.button === 0 && $event.buttons > 0,
          right: $event.button === 1 && $event.buttons > 0,
          handled: false,
          $event: $event,
          $vm: this,
          $element: this.$el
        },
        extra
      );
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/assets/scss/init/variables/variables";
.ds-day {
  flex: 1;
  width: 0;
  padding: 5px;
  overflow: hidden;
  user-select: none;
  min-height: 140px;
  .ds-dom {
    font-family: $body-font-family;
    text-decoration: none;
    color: var(--primary-color);
    opacity: 0.25;
    font-weight: 500;
    width: 100%;
    line-height: 1;
    text-align: right;
    user-select: none;
    padding: 10px;
    display: block;
    font-size: 14px;
    &.ds-today-dom {
      opacity: 1;
      color: var(--primary-color);
      position: relative;
      z-index: 1;
    }
  }
  &--inner {
    background: white;
    height: 100%;
    border-radius: $global-radius;
    font-weight: 500;
    &-scroll {
      height: calc(100% - 34px);
      padding-bottom: 8px;
      .ds-calendar-event-menu {
        height: auto !important;
      }
    }
  }
  &.ds-today {
    .ds-day--inner {
      border: 2px solid var(--primary-color);
    }
  }
}
.ds-out-calendar {
  opacity: 0.4;
}
.ds-day--inner-scroll {
  &::after {
    transition: opacity 150ms ease-in-out;
    position: relative;
    height: 24px;
    width: calc(100% - 20px);
    left: 10px;
    display: block;
    color: #454545;
    border: 1px dashed #dbdbdb;
    cursor: pointer;
    border-radius: $global-radius;
    font-size: 9px;
    text-align: center;
    line-height: 2.6;
    top: 3px;
    font-weight: 500;
    opacity: 0;
  }
}
.ds-day {
  &:hover {
    .ds-day--inner-scroll {
      &::after {
        opacity: 1;
      }
    }
  }
}
.ds-day--inner-scroll {
  &.hide-add {
    height: auto !important;
    &::after {
      display: none !important;
    }
  }
}
</style>
