<template>
  <div class="card event-item">
    <div class="grid-x">
      <div
        class="cell large-6 event-item__header__radius-left-top"
        style="background: var(--menu-color)"
      >
        <div class="card__section">
          <div class="event-item__header"></div>
        </div>
      </div>
      <div
        class="cell large-6 event-item__header__radius-right-top"
        style="background: var(--menu-color)"
      >
        <div class="card__section">
          <div class="event-item__header text-right">
            <span
              class="ab-button--white ab-button--delete"
              v-if="allowEdit && $store.state.is_brand_manager"
              @click="confirmDelete"
              >{{ $t("app.remove") }} <i class="icon-icon-trash"></i
            ></span>
            <span class="ab-button--white ab-button--cancel" @click="close"
              >{{ $t("app.close") }} <i class="icon-close"></i
            ></span>
            <span
              class="ab-button--white ab-button--edit"
              @click="edit"
              v-if="allowEdit && $store.state.is_brand_manager"
              >{{ $t("app.edit") }} <i class="icon-icon-edit-pencil-line"></i
            ></span>
          </div>
        </div>
      </div>
      <div class="cell large-12"><hr /></div>

      <div class="cell large-12">
        <div class="card__section">
          <ab-input-field
            v-if="details.title"
            :label="$t('app.title')"
            :readonly="true"
            v-model="details.title"
          ></ab-input-field>
          <ab-textarea
            v-if="details.description"
            :label="$t('app.description')"
            v-model="details.description"
            icon="icon-description"
            :readonly="true"
          ></ab-textarea>
          <!--          <ab-input-field-->
          <!--                  v-if="details.calendar"-->
          <!--                  :label="$t('calendar.branding_goal')"-->
          <!--                  :readonly="true"-->
          <!--                  icon="icon-brand-goals"-->
          <!--                  v-model="details.calendar.text"-->
          <!--          ></ab-input-field>-->
          <ab-input-field
            v-if="details.calendar && allowEdit"
            :label="$t('calendar.assignee')"
            :readonly="true"
            icon="icon-menu-profile"
            v-model="details.user.text"
          ></ab-input-field>
          <ab-input-field
            v-if="allowEdit"
            :label="$t('calendar.deadline')"
            :readonly="true"
            icon="icon-blog-time"
            v-model="startDate"
          ></ab-input-field>

          <calendar-event-popover-reminders
            :reminders="details.reminders"
          />

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { CalendarEvent, Calendar } from "dayspan";
import moment from "moment";
import backend from "@/backend";
import CalendarEventPopoverReminders from "./CalendarEventPopoverReminders";

export default {
  name: "dsCalendarEventPopover",
  components: {
    CalendarEventPopoverReminders
  },
  props: {
    calendarEvent: {
      required: true,
      type: CalendarEvent
    },

    calendar: {
      required: true,
      type: Calendar
    },

    readOnly: {
      type: Boolean,
      default: false
    },

    edit: {
      type: Function
    },

    allowEditOnReadOnly: {
      type: Boolean,
      default() {
        return this.$dsDefaults().allowEditOnReadOnly;
      }
    },

    close: {
      type: Function
    },

    formats: {
      validate(x) {
        return this.$dsValidate(x, "formats");
      },
      default() {
        return this.$dsDefaults().formats;
      }
    },

    labels: {
      validate(x) {
        return this.$dsValidate(x, "labels");
      },
      default() {
        return this.$dsDefaults().labels;
      }
    }
  },
  methods: {
    closeCheck(close) {
      if (!this.dirty) {
        close();
        return;
      }
      this.$modal.show("dialog", {
        title: "",
        text: this.$t("app.cancel_create"),
        buttons: [
          {
            title: this.$t("app.no"),
            handler: () => {
              this.$modal.hide("dialog");
            }
          },
          {
            title: this.$t("app.yes"),
            default: true,
            handler: () => {
              close();
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    },
    dateFormatted(date) {
      moment(date, "YYYY-MM-DD");
    },
    confirmDelete() {
      this.$modal.show("dialog", {
        title: "",
        text: this.$t("calendar.confirm_removal"),
        buttons: [
          {
            title: this.$t("app.no"),
            handler: () => {
              this.$modal.hide("dialog");
            }
          },
          {
            title: this.$t("app.yes"),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/events/` + this.details.id)
                .then(response => {
                  this.$modal.hide("confirmDeletePopup");

                  this.$emit("finish", this.calendarEvent);

                  this.close();

                  this.$notify({
                    group: "custom-template",
                    title: false,
                    position: "bottom left",
                    text: this.$t("calendar.item_removed")
                  });
                });
              this.$modal.hide("dialog");
            }
          }
        ]
      });
    }
  },
  computed: {
    slotData() {
      return {
        calendarEvent: this.calendarEvent,
        calendar: this.calendar,
        userEvent: this.userEvent,
        user: this.user,
        edit: this.edit,
        close: this.close,
        details: this.details,
        readOnly: this.readOnly
      };
    },

    classes() {
      return {
        "ds-event-cancelled": this.calendarEvent.cancelled
      };
    },

    styleHeader() {
      return {
        backgroundColor: this.details.calendar.color || this.details.color,
        color: this.details.forecolor
      };
    },

    styleButton() {
      return {
        color: this.details.forecolor
      };
    },

    startDate() {
      return this.calendarEvent.start.format(this.formats.start);
    },

    busyness() {
      return this.details.busy ? this.labels.busy : this.labels.free;
    },

    hasBusy() {
      return typeof this.details.busy === "boolean";
    },

    occurs() {
      return this.$dayspan.getEventOccurrence(
        this.calendarEvent.schedule,
        this.calendarEvent.start,
        this.labels,
        this.formats
      );
    },

    details() {
      return this.calendarEvent.event.data;
    },

    allowEdit() {
      return this.details.editable;
    },

    isReadOnly() {
      return this.readOnly || this.$dayspan.readOnly || !this.details.editable;
    }
  },

  data: vm => ({})
};
</script>

<style scoped lang="scss">
.v-icon {
  font-size: 16px;
  height: inherit;
  line-height: inherit;
}
.v-avatar {
  i[class^="icon-"] {
    font-size: 18px;
    position: relative;
    top: -3px;
  }
}
</style>
