<template>
  <div class="ds-expand ds-calendar-app remove-padding">
    <v-toolbar
      app
      flat
      absolute
      class=""
      :clipped-left="$vuetify.breakpoint.lgAndUp"
    >
      <div class="grid-x align-middle">
        <div class="cell shrink border-right">
          <slot name="today" v-bind="{ setToday, todayDate, calendar }">
            <v-btn
              slot="activator"
              class="test"
              depressed
              :icon="$vuetify.breakpoint.smAndDown"
              @click="setToday"
            >
                <span v-if="$vuetify.breakpoint.mdAndUp">{{
                    labels.today
                  }}</span>
              <v-icon v-else>{{ labels.todayIcon }}</v-icon>
            </v-btn>
          </slot>
        </div>
        <div class="cell auto text-left border-right">
          <div class="v-tooltip--month-nav">
            <slot name="prev" v-bind="{ prev, prevLabel, calendar }">

              <v-btn
                slot="activator"
                icon
                depressed
                class="v-toolbar--month-prev"
                @click="prev"
              >
              </v-btn>

            </slot>
            <slot name="summary" v-bind="{ summary, calendar }">

              <span class="month">{{ summary }}</span>

            </slot>
            <slot name="next" v-bind="{ next, nextLabel, calendar }">

                <v-btn
                  slot="activator"
                  icon
                  depressed
                  class="v-toolbar--month-next"
                  @click="next"
                >
                </v-btn>

            </slot>
          </div>
        </div>
        <div class="cell shrink text-left">
          <dropdown-item
            ref="dropdown"
            :class-name="'asset-filter'"
            :class="'asset-filter calendar-filter'"
            :isIcon="false"
            :align="'bottom'"
            :width="400"
            :y="0"
            :x="0"
          >
            <template slot="btn">
            <span class="asset-filter__button">
              {{ $t('calendar.calendars') }} <div class="calendar-count"> {{ selectedCalendars.length }} </div>
        </span>
            </template>
            <template slot="body">
              <div class="asset-filter__dropdown-content">
                <span
                  v-for="(availableCalendar, index) in availableCalendars"
                  :key="index"
                >
                <ab-checkbox @input="updateCalendarVisibility(availableCalendar.id)" :value="getCalendarVisibility(availableCalendar.id)" :label="availableCalendar.name" :item="availableCalendar.id"/>
              </span>
              </div>
            </template>
          </dropdown-item>
        </div>
      </div>
    </v-toolbar>
    <v-content class="ds-expand">
      <v-container fluid fill-height class="ds-calendar-container">
        <ds-gestures @swipeleft="next" @swiperight="prev">
          <div v-if="currentType.schedule" class="ds-expand">
            <slot
              name="calendarAppAgenda"
              v-bind="{
                $scopedSlots,
                $listeners,
                calendar,
                add,
                edit,
                viewDay
              }"
            >
              <ds-agenda
                v-bind="{ $scopedSlots }"
                v-on="$listeners"
                :read-only="readOnly"
                :calendar="calendar"
                @add="add"
                @edit="edit"
                @view-day="viewDay"
              ></ds-agenda>
            </slot>
          </div>

          <div v-else class="ds-expand">
            <slot
              name="calendarAppCalendar"
              v-bind="{
                $scopedSlots,
                $listeners,
                calendar,
                add,
                addAt,
                edit,
                viewDay,
                handleAdd,
                handleMove
              }"
            >
              <ds-calendar
                ref="calendar"
                v-bind="{ $scopedSlots }"
                v-on="$listeners"
                :calendar="calendar"
                :read-only="readOnly"
                @add="add"
                @add-at="addAt"
                @edit="edit"
                @view-day="viewDay"
                @added="handleAdd"
                @moved="handleMove"
              ></ds-calendar>
            </slot>
          </div>
        </ds-gestures>

        <slot
          name="calendarAppEventDialog"
          v-bind="{ $scopedSlots, $listeners, calendar, eventFinish }"
        >
          <ds-event-dialog
            ref="eventDialog"
            v-bind="{ $scopedSlots }"
            v-on="$listeners"
            :calendar="calendar"
            :read-only="readOnly"
            @saved="eventFinish"
            @actioned="eventFinish"
          ></ds-event-dialog>
        </slot>

        <slot
          name="calendarAppOptions"
          v-bind="{ optionsVisible, optionsDialog, options, chooseOption }"
        >
          <v-dialog
            ref="optionsDialog"
            v-model="optionsVisible"
            v-bind="optionsDialog"
            width="300"
            :fullscreen="$dayspan.fullscreenDialogs"
          >
            <v-list>
              <template v-for="option in options">
                <v-list-tile :key="option.text" @click="chooseOption(option)">
                  {{ option.text }}
                </v-list-tile>
              </template>
            </v-list>
          </v-dialog>
        </slot>

        <slot
          name="calendarAppPrompt"
          v-bind="{ promptVisible, promptDialog, promptQuestion, choosePrompt }"
        >
          <v-dialog
            ref="promptDialog"
            v-model="promptVisible"
            width="300"
            v-bind="promptDialog"
          >
            <v-card>
              <div class="card__section">
                <div class="v-dialog__title">{{ $t("app.remove_item") }}?</div>
                <p>{{ promptQuestion }}</p>
                <div class="spacer--20"></div>
                <p class="text-right">
                  <a flat @click="choosePrompt(false)" class="close-button">{{
                    $t("app.no")
                  }}</a>
                  <a flat @click="choosePrompt(true)" class="color--primary">{{
                    $t("app.confirm")
                  }}</a>
                </p>
              </div>
            </v-card>
          </v-dialog>
        </slot>

        <!--                <slot name="calendarAppAdd" v-bind="{allowsAddToday, addToday}">-->
        <!--                    <v-fab-transition v-if="!readOnly">-->
        <!--                        <button-->
        <!--                            class="button&#45;&#45;social"-->
        <!--                            fixed top right fab-->
        <!--                            v-model="allowsAddToday"-->
        <!--                            @click="addToday">-->
        <!--                            Event aanmaken <i class="icon-plus"></i>-->
        <!--                        </button>-->
        <!--                    </v-fab-transition>-->
        <!--                </slot>-->

        <slot name="containerInside" v-bind="{ events, calendar }"></slot>
      </v-container>
    </v-content>
  </div>
</template>

<script>
import {
  Constants,
  Sorts,
  Calendar,
  Day,
  Units,
  Weekday,
  Month,
  DaySpan,
  PatternMap,
  Time,
  Op
} from "dayspan";
import backend from "@/backend";
export default {
  name: "dsCalendarApp",

  props: {
    events: {
      type: Array
    },
    calendar: {
      type: Calendar,
      default () {
        return Calendar.months()
      }
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    availableCalendars: {
      type: Array,
      default: []
    },
    types: {
      type: Array,
      default: function () {
        // return this.$dsDefaults().types;
        return [
          // {id: 'D', label: 'Dag',   type: Units.DAY,   size: 1,  focus: 0.4999, repeat: true,  listTimes: true,  updateRows: true,  schedule: false },
          // {id: 'W', label: 'Week',  type: Units.WEEK,  size: 1,  focus: 0.4999, repeat: true,  listTimes: true,  updateRows: true,  schedule: false },
          {
            id: 'M',
            label: 'Maand',
            type: Units.MONTH,
            size: 1,
            focus: 0.4999,
            repeat: true,
            listTimes: false,
            updateRows: true,
            schedule: false
          }
          // {id: 'Y', label: 'Jaar',  type: Units.YEAR,  size: 1,  focus: 0.4999, repeat: true,  listTimes: false, updateRows: true,  schedule: false }
        ];
      }
    },
    allowsAddToday: {
      type: Boolean,
      default() {
        return this.$dsDefaults().allowsAddToday;
      }
    },
    formats: {
      validate(x) {
        return this.$dsValidate(x, "formats");
      },
      default() {
        return this.$dsDefaults().formats;
      }
    },
    labels: {
      validate(x) {
        return this.$dsValidate(x, "labels");
      },
      default() {
        return this.$dsDefaults().labels;
      }
    },
    styles: {
      validate(x) {
        return this.$dsValidate(x, "styles");
      },
      default() {
        return this.$dsDefaults().styles;
      }
    },
    optionsDialog: {
      validate(x) {
        return this.$dsValidate(x, "optionsDialog");
      },
      default() {
        return this.$dsDefaults().optionsDialog;
      }
    },
    promptDialog: {
      validate(x) {
        return this.$dsValidate(x, "promptDialog");
      },
      default() {
        return this.$dsDefaults().promptDialog;
      }
    }
  },

  data: vm => ({
    drawer: null,
    optionsVisible: false,
    options: [],
    promptVisible: false,
    promptQuestion: '',
    promptCallback: null,
    selectedCalendars: []
  }),

  watch: {
    events: 'applyEvents',
    calendar: 'applyEvents',
    availableCalendars: 'updateAvailableCalendars'
  },

  computed: {
    currentType: {
      get () {
        return (
          this.types.find(
            type =>
              type.type === this.calendar.type &&
              type.size === this.calendar.size
          ) || this.types[0]
        )
      },
      set(type) {
        this.rebuild(undefined, true, type);
      }
    },

    summary() {
      let small = this.$vuetify.breakpoint.xs;

      if (small) {
        return this.calendar.start.format(this.formats.xs);
      }

      let large = this.$vuetify.breakpoint.mdAndUp;

      return this.calendar.summary(false, !large, false, !large);
    },

    todayDate() {
      return this.$dayspan.today.format(this.formats.today);
    },

    nextLabel() {
      return this.labels.next(this.currentType);
    },

    prevLabel() {
      return this.labels.prev(this.currentType);
    },

    toolbarStyle() {
      let large = this.$vuetify.breakpoint.lgAndUp;

      return large ? this.styles.toolbar.large : this.styles.toolbar.small;
    },

    hasCreatePopover() {
      return !!this.$scopedSlots.eventCreatePopover;
    },

    canAddDay() {
      return (
        this.$dayspan.features.addDay &&
        !this.readOnly &&
        !this.$dayspan.readOnly
      );
    },

    canAddTime() {
      return (
        this.$dayspan.features.addTime &&
        !this.readOnly &&
        !this.$dayspan.readOnly
      );
    }
  },

  mounted() {
    if (!this.$dayspan.promptOpen) {
      this.$dayspan.promptOpen = (question, callback) => {
        this.promptVisible = false
        this.promptQuestion = question
        this.promptCallback = callback
        this.promptVisible = true
      }
    }
  },

  methods: {
    updateCalendarVisibility (id) {
      let idString = id.toString()
      if (this.selectedCalendars.includes(idString) === false) {
        this.selectedCalendars.push(idString)
      } else {
        let index = this.selectedCalendars.indexOf(idString)
        this.selectedCalendars.splice(index, 1)
      }
      localStorage.setItem('selectedCalendars', this.selectedCalendars)
      this.triggerChange()
    },
    updateAvailableCalendars () {
      let localStorageCalendars = localStorage.getItem('selectedCalendars')
      if (localStorageCalendars === null) {
        for (let key in this.availableCalendars) {
          this.selectedCalendars.push(this.availableCalendars[key].id)
        }
        localStorage.setItem('selectedCalendars', this.selectedCalendars)
        this.triggerChange()
      }
      this.selectedCalendars = localStorage.getItem('selectedCalendars').split(',')
    },
    getCalendarVisibility (id) {
      let idString = id.toString()
      return this.selectedCalendars.includes(idString)
    },
    setState (state) {
      state.eventSorter = state.listTimes
        ? Sorts.List([Sorts.FullDay, Sorts.Start])
        : Sorts.Start

      this.calendar.set(state)

      this.triggerChange()
    },

    applyEvents () {
      if (this.events) {
        this.calendar.removeEvents()
        this.calendar.addEvents(this.events)
      }
    },

    isType(type, aroundDay) {
      let cal = this.calendar;

      return (
        cal.type === type.type &&
        cal.size === type.size &&
        (!aroundDay || cal.span.matchesDay(aroundDay))
      );
    },

    rebuild(aroundDay, force, forceType) {
      let type = forceType || this.currentType || this.types[2];

      if (this.isType(type, aroundDay) && !force) {
        return;
      }

      let input = {
        type: type.type,
        size: type.size,
        around: aroundDay,
        eventsOutside: true,
        preferToday: false,
        listTimes: type.listTimes,
        updateRows: type.updateRows,
        updateColumns: type.listTimes,
        fill: !type.listTimes,
        otherwiseFocus: type.focus,
        repeatCovers: type.repeat
      };

      this.setState(input);
    },

    next() {
      this.calendar.unselect().next();

      this.triggerChange();
    },

    prev() {
      this.calendar.unselect().prev();

      this.triggerChange();
    },

    setToday() {
      this.rebuild(this.$dayspan.today);
    },

    viewDay(day) {
      this.rebuild(day, false, this.types[0]);
    },

    edit(calendarEvent) {
      let eventDialog = this.$refs.eventDialog;

      eventDialog.edit(calendarEvent);
    },

    editPlaceholder(createEdit) {
      let placeholder = createEdit.calendarEvent;
      let details = createEdit.details;
      let eventDialog = this.$refs.eventDialog;
      let calendar = this.$refs.calendar;

      eventDialog.addPlaceholder(placeholder, details);
      eventDialog.$once("close", calendar.clearPlaceholder);
    },

    add(day) {
      if (!this.canAddDay) {
        return;
      }

      let eventDialog = this.$refs.eventDialog;
      let calendar = this.$refs.calendar;
      let useDialog = !this.hasCreatePopover;

      calendar.addPlaceholder(day, true, useDialog);

      if (useDialog) {
        eventDialog.add(day);
        eventDialog.$once("close", calendar.clearPlaceholder);
      }
    },

    addAt(dayHour) {
      if (!this.canAddTime) {
        return;
      }

      let eventDialog = this.$refs.eventDialog;
      let calendar = this.$refs.calendar;
      let useDialog = !this.hasCreatePopover;
      let at = dayHour.day.withHour(dayHour.hour);

      calendar.addPlaceholder(at, false, useDialog);

      if (useDialog) {
        eventDialog.addAt(dayHour.day, dayHour.hour);
        eventDialog.$once("close", calendar.clearPlaceholder);
      }
    },

    addToday() {
      if (!this.canAddDay) {
        return;
      }

      let eventDialog = this.$refs.eventDialog;
      let calendar = this.$refs.calendar;
      let useDialog = !this.hasCreatePopover || !calendar;

      let day = this.$dayspan.today;

      if (!this.calendar.filled.matchesDay(day)) {
        let first = this.calendar.days[0];
        let last = this.calendar.days[this.calendar.days.length - 1];
        let firstDistance = Math.abs(first.currentOffset);
        let lastDistance = Math.abs(last.currentOffset);

        day = firstDistance < lastDistance ? first : last;
      }

      calendar && calendar.addPlaceholder(day, true, useDialog);

      if (useDialog) {
        eventDialog.add(day);

        calendar && eventDialog.$once("close", calendar.clearPlaceholder);
      }
    },

    handleAdd(addEvent) {
      let eventDialog = this.$refs.eventDialog;
      let calendar = this.$refs.calendar;

      addEvent.handled = true;

      if (!this.hasCreatePopover) {
        if (addEvent.placeholder.fullDay) {
          eventDialog.add(addEvent.span.start, addEvent.span.days(Op.UP));
        } else {
          eventDialog.addSpan(addEvent.span);
        }

        eventDialog.$once("close", addEvent.clearPlaceholder);
      } else {
        calendar.placeholderForCreate = true;
      }
    },

    handleMove(moveEvent) {
      let calendarEvent = moveEvent.calendarEvent;
      let target = moveEvent.target;
      let targetStart = target.start;
      let sourceStart = calendarEvent.time.start;
      let schedule = calendarEvent.schedule;
      let options = [];
      moveEvent.handled = true;

      let callbacks = {
        cancel: () => {
          moveEvent.clearPlaceholder();
        },
        single: () => {
          calendarEvent.move(targetStart);
          moveEvent.clearPlaceholder();
          backend
            .put(`${process.env.VUE_APP_URL}/events/` + calendarEvent.data.id, {
              title: calendarEvent.data.title,
              calendar: calendarEvent.data.calendar.text,
              content: calendarEvent.data.description,
              date:
                target.end.year +
                "-" +
                (target.end.month * 1 + 1) +
                "-" +
                target.end.dayOfMonth,
              user_id: calendarEvent.data.user_id
            })
            .then(response => {
              this.$notify({
                group: "custom-template",
                title: false,
                position: "bottom left",
                text: "Item moved"
              });
              this.eventsRefresh();
            });

          this.$emit("event-update", calendarEvent.event);
        },
        instance: () => {
          calendarEvent.move(targetStart);
          this.eventsRefresh();
          moveEvent.clearPlaceholder();

          this.$emit("event-update", calendarEvent.event);
        },
        duplicate: () => {
          schedule.setExcluded(targetStart, false);
          this.eventsRefresh();
          moveEvent.clearPlaceholder();

          this.$emit("event-update", calendarEvent.event);
        },
        all: () => {
          schedule.moveTime(sourceStart.asTime(), targetStart.asTime());
          this.eventsRefresh();
          moveEvent.clearPlaceholder();

          this.$emit("event-update", calendarEvent.event);
        }
      };

      options.push({
        text: this.labels.moveCancel,
        callback: callbacks.cancel
      });

      if (schedule.isSingleEvent()) {
        options.push({
          text: this.labels.moveSingleEvent,
          callback: callbacks.single
        });

        if (this.$dayspan.features.moveDuplicate) {
          options.push({
            text: this.labels.moveDuplicate,
            callback: callbacks.duplicate
          });
        }
      } else {
        if (this.$dayspan.features.moveInstance) {
          options.push({
            text: this.labels.moveOccurrence,
            callback: callbacks.instance
          });
        }

        if (this.$dayspan.features.moveDuplicate) {
          options.push({
            text: this.labels.moveDuplicate,
            callback: callbacks.duplicate
          });
        }

        if (
          this.$dayspan.features.moveAll &&
          !schedule.isFullDay() &&
          targetStart.sameDay(sourceStart)
        ) {
          options.push({
            text: this.labels.moveAll,
            callback: callbacks.all
          });
        }
      }

      this.options = options;
      this.optionsVisible = true;
    },

    chooseOption(option) {
      if (option) {
        option.callback();
      }

      this.optionsVisible = false;
    },

    choosePrompt(yes) {
      this.promptCallback(yes);
      this.promptVisible = false;
    },

    eventFinish(ev) {
      this.triggerChange();
    },

    eventsRefresh() {
      this.calendar.refreshEvents();

      this.triggerChange();
    },

    triggerChange() {
      this.$emit("change", {
        calendar: this.calendar
      });
    }
  }
};
</script>

<style lang="scss">
@import "~@/assets/scss/init/variables/variables";
.remove-padding {
  margin: -64px 0 0 0 !important;
}

.ds-app-calendar-toolbar {
  padding: 0 5px !important;
  .v-toolbar__content {
    padding: 10px 0px !important;
    border: 0px;
    display: block;
    span {
      display: inline-block;
    }
    .v-tooltip--month {
      top: -5px;
      padding-top: 5px;
      font-weight: 500;
      font-size: 14px;
      color: #454545;
    }
  }
}
.v-toolbar {
  background: $white !important;
  z-index: 200 !important;
  width:auto !important;
  border-radius: 5px !important;
  position: fixed !important;
  margin: $page-content-padding !important;
  left: $sidebar-width !important;
  &--btn-navigation {
    width: 100%;
    display: block;
    flex: 100%;
    text-align: left;

    button {
      border-radius: $global-radius;
      padding: 0 0px;
      margin: 0;
      height: 50px;
    }
  }
}

.calendar-filter {
  margin-left: 8px;
  border: 1px solid $ui-line-color;
  border-radius: $global-radius;
  padding: 2px 8px;
  padding-top: 0;
  height: 36px;

  .asset-filter__button {
    line-height: 34px;
    width: 382px;
    color: rgba(0, 0, 0, .87);
  }

  .bp-dropdown__body {
    left: -1px !important;
    width: 400px;
    top: calc(100% - 2px) !important;
    border-radius: 0px 0px $global-radius $global-radius;
    border-top: 0px;

    span {
      padding-left: 8px;
    }
  }

  .calendar-count {
    border: 2px solid $light-gray;
    border-radius: 100%;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    display: none;
  }
}

.ds-skinny-button {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.ds-expand {
  width: 100%;
  height: 100%;
}

.ds-calendar-container {
  padding: 0 !important;
  position: relative;
}

.v-btn--floating.ds-add-event-today {
  border-radius: 100%;
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;

  .v-icon {
    width: 24px;
    height: 24px;
  }
}
</style>
